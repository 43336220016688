import React, { useCallback, useState }from 'react'
import { graphql } from "gatsby"
import Layout from "../components/layout"
import EleccionesVideoCarousel from '../components/video-carousel'
import Nota from '../components/Nota'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Container, Grid } from "@material-ui/core";
// import bannerFiscaliza from '../assets/banners/BANNER_FISCAL.svg'
// import boletaCABA from '../assets/banners/boleta-caba.png'
// import boletaPBA from '../assets/banners/boleta-pba.png'
// import boletaS from '../assets/banners/boleta-salta.png'
// import boletaT from '../assets/banners/boleta-tucuman.jpg'
// import boletaSE from '../assets/banners/boleta-santiago.png'
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles(theme => ({
  bannerDiv:{
    position: 'relative',
    //backgroundColor: '#e20613',
  },
  bannerPDF:{
    marginBottom:'-0.3rem',
    [theme.breakpoints.up('md')]: {
      width:"90%",
    },
    [theme.breakpoints.up('xl')]: {
      width:"100%",
    },
  },
  carousel: {
      borderBottom: '2px solid black',
  },
  tab:{
    '&:hover': {
      color: 'black',
      opacity: 1,
    },
    '&$selected': {
      color: 'black',
      fontWeight: 700,
    },
  }
}))

const destacadas = [
  '5531',
  '5477',
  '5263',
  '5431',
]

const StyledTabs = withStyles({
  root: {
    // borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: 'black',
  },
})(Tabs);

export default ({ data }) => {
  const { 
    allMarkdownRemark:{
      edges: notasRaw
    } 
  } = data 
  const notas = notasRaw.map(i => i.node)
  // const notasDestacadas = notas.filter(item => destacadas.includes(item.fields.slug.split('-')[0]))
  // const notasRestantes = notas.filter(item => !destacadas.includes(item.fields.slug.split('-')[0]))
  const classes = useStyles();
  const [verBanner, setVerBanner] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const [tab, setTab] = React.useState(0);
  const handleTab = useCallback((event, newValue) => {
    setTab(newValue);
  },[])

  const handleCloseBanner = useCallback(function(e){
    e.preventDefault();
    setVerBanner(false);
  },[])

  return(
      <Layout>
        <Container maxWidth="xl">
          {/* <div style={{marginBottom: '1rem'}}>
            <StyledTabs
              value={tab}
              onChange={handleTab}
              aria-label="tabs"
              centered
            >
              <Tab className={classes.tab} label="Nuestras Boletas" />
              <Tab className={classes.tab} label="Donde voto?" />
              <Tab className={classes.tab} label="Videos" />
            </StyledTabs>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              {
                tab === 1 && (
                  <div>
                    <iframe width={matches ? '350px' : '500px'} height={matches ? '350px' : '500px'} src="https://www.padron.gov.ar/"></iframe>
                  </div>
                )
              }
              {
                tab === 0 && (
                  <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                    <h3>CABA</h3>
                    <div>
                      <img style={matches ? { maxWidth: '90%', height: 'auto'} : {}} src={boletaCABA} alt="Boleta Politica Obrera CABA" />
                    </div>
                    <h3>BUENOS AIRES</h3>
                    <div>
                      <img style={{}} src={boletaPBA} alt="Boleta Politica Obrera PBA" />
                    </div>
                    <h3>SALTA</h3>
                    <div>
                      <img style={{}} src={boletaS} alt="Boleta Politica Obrera SALTA" />
                    </div>
                    <h3>TUCUMÁN</h3>
                    <div>
                      <img style={matches ? { maxWidth: '90%', height: 'auto'} : {}} src={boletaT} alt="Boleta Politica Obrera TUCUMÁN" />
                    </div>
                    <h3>SANTIAGO DEL ESTERO</h3>
                    <div>
                      <img src={boletaSE} alt="Boleta Politica Obrera SANTIAGO DEL ESTERO" />
                    </div>
                  </div>
                )
              } 
              {
                tab === 2 && (
                  <div style={{ width: matches ? '100%' : '80%' }}>
                    <VideoCarousel celu={matches} />
                  </div>
                )
              }
            </div>
          </div> */}
          {
            verBanner && (
              <div style={{textAlign:'center', marginBottom: '1rem'}}>
                <a key="form-fiscales" target="_blank" rel="noopener norefferer" href="https://docs.google.com/forms/d/e/1FAIpQLSe2OVCfvdXunzsH3K7Vpw-ij-dPFAtYL-f5y2lYsRiZklGJAw/viewform">
                  <div className={classes.bannerDiv}>
                    <span onClick={handleCloseBanner} style={{ color:'black' ,position: 'absolute', right: '0.3rem', top: '0.3rem', z:'10', fontSize: matches ? '14px': '24px' }} className="material-icons">
                      close
                    </span>
                    {/* <img className={classes.bannerPDF} src={bannerFiscaliza} alt="fiscaliza!" /> */}
                  </div>
                </a>
              </div>
            )
          }
          <div style={{ display: 'flex', marginTop: '2rem' }}>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} lg={12} className={classes.carousel}>
                <VideoCarousel celu={matches} />
              </Grid> */}
              <Grid item xs={12} lg={12}>
              {/* <div style={{ padding: '2rem 0rem', color:'#EE4027'}}>
               <h2>ELECCIONES 2021</h2>
              </div> */}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {
                    notas.map((nota, i) => (
                      <div key={`div-nota-destacada-${i}`} style={{ marginBottom: '1rem' }}>
                        <Nota key={`nota-destacada-${i}`} node={nota} imgFluidDefault={null} />
                      </div>
                    ))
                  }
                </div>
              </Grid>
            </Grid>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
            <div style={{ width: matches ? '100%' : '80%' }}>
              <EleccionesVideoCarousel celu={matches} />
            </div>
          </div>
          {/* <div dangerouslySetInnerHTML={{__html: prueba}}>  2018114674974892
            
          </div> */}
          {/* <div style={{ padding: '2rem 0rem', color:'#EE4027'}}>
            <h2>MÁS I</h2>
          </div> */}
          {/* <div style={{ padding: '2rem 0rem'}}>
            {
              notasRestantes.map((nota, i) => (
                <div style={{ marginBottom: '1rem' }} key={`div-nota-restantes-${i}`}>
                  <Nota key={`nota-restantes-${i}`} node={nota} imgFluidDefault={null} />
                </div>
              ))
            }
          </div> */}
        </Container>
      </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/notas/"}, frontmatter: {tags: {in: "PASO2021"}}}
      sort: { fields: [frontmatter___date], order: DESC }
      ) {
      edges {
        node {
          frontmatter {
            image
            title
            date
            author
            seccion
          }
          fields {
            slug
          }
        }
      }
    }
  }
`