import React, { useState } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from 'react-responsive-carousel'
// import FacebookPlayer from 'react-facebook-player';

const data = {
    tucuman: [
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/gIRloA4OOZU",
            title: 'Daniel Blanco: "Si votan por los partidos de la grieta vuelve el FMI"',
        },
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/KAPg_X0XAWE",
            title: 'Alejandra del Castillo en acto de cierre de campaña de Política Obrera (8/9/21)',
        },
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/J18Zw5wmcE4",
            title: 'Ariel Osatinsky, precandidato a Dip. Nac. en acto de cierre de campaña de Política Obrera (8/9/21)',
        },
    ],
    salta:[
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/WBaq-NLZHQA",
            title: 'Julio Quintana, precandidato a Diputado por Política Obrera en Tartagal',
        },
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/4K9Lamnj8ww",
            title: 'Julio Quintana en "Staff de Noticias" - Mosconi',
        },
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/KAVopgyVSGA",
            title: 'CUÁL ES LA CARACTERÍSTICA DE LA ACTUAL CRISIS - JULIO QUINTANA, PRECANDIDATO A DIPUTADO NACIONAL',
        },
    ],
    caba:[
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/R72T5OhjbZo",
            title: 'Ramal: "En el año de la pandemia, en donde todos la pasamos muy mal, los bancos ganaron como nunca"',
        },
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/3Ej7hAImqAk",
            title: 'Marcelo Ramal en C5N: "Todo el sistema impositivo se descarga sobre la espalda del trabajador"',
        },
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/fzhrexLFFNc",
            title: 'Florencia Palombo: "La presencialidad escolar detonó la segunda ola" 2/8/21',
        },
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/U8cZatiFsxk",
            title: 'Pablo Viñas y Trabajadores EMA en Radio Belgrano, 28/8/2021',
        },
    ],
    pba:[
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/eWeKuJTgbKI",
            title: 'Jorge Altamira en AM 1140 Independencia, 6/9/2021',
        },
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/o-2mxOEUEs8",
            title: 'Eva Gutiérrez en FM Crisol "Otras Voces", 4/9/2021',
        },
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/Ku3QvGCpLM8",
            title: "Jorge Altamira en Super Canal Noticias, 26/8/21",
        },
    ],
    inicial:[
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/QkDu_F42xx8",
            title: 'Jorge Altamira y Marcelo Ramal en A DOS VOCES TN 8/9/2021',
        },
        {
            type: "youtube",
            src: "https://www.youtube.com/embed/qazQkso-noI",
            title: '#Elecciones2021 | Desde el #Subte, por una Política Obrera',
        },
        {
          type: "youtube",
          src: "https://www.youtube.com/embed/ZJfnR8Pogvk",
          title: "Marcelo Ramal: #Elecciones2021 | Por una Asamblea Constituyente",
        },
        {
          type: "youtube",
          src: "https://www.youtube.com/embed/Idt1LWMHlVg",
          title: "Jorge Altamira en: #Elecciones2021 | Trabajadores y pandemia",
        },
    ],
}

export default function EleccionesVideoCarousel({ celu }) {
    const [selected, setSelected] = useState('inicial')
    return (
        <div style={{ backgroundColor: '#9F9E9E', paddingTop: '1.5rem', marginBottom: '1rem' }}>
            <div style={{ textAlign: 'center', marginBottom: '1rem' }}>
                <select onChange={(e) => setSelected(e.target.value)} name="select">
                    <option value="inicial" selected>NACIONAL</option>
                    <option value="pba">BUENOS AIRES</option>
                    <option value="caba">CABA</option>
                    <option value="salta">SALTA</option>
                    <option value="tucuman">TUCUMÁN</option>
                </select>
            </div>
            <Carousel showThumbs={false} >
                {
                    data[selected].map( (i, index) => {
                        return (
                            <div key={`carousel-item-${index}`}>
                                {
                                    i.type === "youtube" && (
                                        <iframe
                                            height={celu ? '200' : '500'}
                                            src={i.src}
                                            title={i.title}
                                            frameBorder="0" 
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                            allowFullScreen>
                                        </iframe>
                                    )
                                }
                                {/* {
                                    i.type === "facebook" && (
                                        <div style={{display: 'flex'}}>
                                            <FacebookPlayer
                                                appId={'2018114674974892'}
                                                videoId={i.src}
                                                id={`f-live-video-player-${index}`}
                                                allowfullscreen={true}
                                                autoplay={false}
                                                width={celu ? 200 : 1000}
                                            />
                                        </div>
                                    )
                                } */}
                                {
                                    !celu && (
                                        <p className="legend" style={{ bottom: 18 }}>{i.title}</p>
                                    )
                                }
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}